var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../../assets/image/productCase/banner-pharmaceutical-1.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"60rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"10rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explainBox",staticStyle:{"height":"auto","min-height":"15.3rem"}},[_c('div',{staticClass:"head-explainBox-title1"},[_vm._v("IT 服务门户")]),_c('div',{staticClass:"head-explainBox-content1"},[_vm._v("某大型医药企业")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"selected-box"},[_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("问题跟踪困难")]),_c('div',{staticClass:"content"},[_vm._v("企业内部缺乏统一的IT服务管理平台，无法跟踪问题处理进度。")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("运维工作难以考核")]),_c('div',{staticClass:"content"},[_vm._v("运维人员主要依靠电话或邮件来受理用户日常遇到的IT问题，没有办法系统地考核其工时和工作量。")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("缺少标准化IT服务管理流程")]),_c('div',{staticClass:"content"},[_vm._v("IT部门内部没有形成标准化的事件管理、问题管理、变更管理等服务流程。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-box"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-pharmaceutical-1.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("服务门户")]),_c('div',{staticClass:"title2"},[_vm._v("通过 Vticket 运维端及用户端，可以统一管理并跟踪每个问题的处理状态。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-pharmaceutical-2.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("多渠道工单提报及分析统计")]),_c('div',{staticClass:"title2"},[_vm._v("借助平台BI功能可以很方便地统计分析工单量及每个事件的处理时长。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-pharmaceutical-3.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("自动化流程")]),_c('div',{staticClass:"title2"},[_vm._v("平台具有标准化的IT服务流程设计方案，实现流程标准化和自定义。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("得以追踪问题的处理进度。据统计，问题的平均解决时间缩短了 30%。")])]),_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("可以系统地统计每个运维人员的工时及工作量，让考核结果更准确。")])]),_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("建设了标准化的事件管理、问题管理、变更管理等服务流程，提高了运维效率。")])])])
}]

export { render, staticRenderFns }